.grid {
	background-color: var(--color-primary-dark);
}

.image-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;

	@media (min-width: 768px) {
		grid-template-columns: 39.210694% 6.747295% 11.171865% 26.102482% 14.831318%;
		grid-template-rows: 16% 16% 18% 6% 3% 29% 12%;
	}

	& img {
		max-height: 350px;

		@media (min-width: 768px) {
			max-height: none;
		}
	}

	& > *:first-child {
		color: var(--color-white);
		width: 100%;
		z-index: 1;

		@media (min-width: 768px) {
			grid-column: 1 / span 3;
			grid-row: 1 / span 2;
		}
	}

	& > *:nth-child(2) {
		height: 100%;
		object-fit: cover;
		width: 100%;

		@media (min-width: 768px) {
			grid-column: 3 / -1;
			grid-row: 2 / span 3;
		}
	}

	& > *:nth-child(3) {
		height: 100%;
		object-fit: cover;
		width: 100%;

		@media (min-width: 768px) {
			grid-row: 4 / 7;
		}
	}

	& > *:last-child {
		height: 100%;
		object-fit: cover;
		width: 100%;

		@media (min-width: 768px) {
			grid-column: 3 / 5;
			grid-row: 6 / -1;
		}
	}
}