.trustbar {
	align-items: center;
	background-color: var(--color-primary-dark);
	display: flex;
	flex-wrap: wrap;
	gap: var(--sp-xl);
	justify-content: center;
	padding-bottom: var(--sp-3xl);
	padding-top: var(--sp-3xl);

	@media (min-width: 768px) {
		flex-wrap: nowrap;
		gap: var(--sp-3xl);
	}

	& img {
		overflow: hidden;
	}
}