:root {
	--font-display: neue-kabel, sans-serif;
	--font-body: roc-grotesk, sans-serif;
	--font-serif: space-mono, mono;

	--color-black: #000000;
	--color-ebony-clay: #272e3a;
	--color-nutmeg: #783125;
	--color-pigeon-post: #a3bbd7;
	--color-burnt-sienna: #f1634a;
	--color-mercury: #e5e5e5;
	--color-white: #ffffff;

	--color-primary-dark: var(--color-ebony-clay);
	--color-primary-light: var(--color-mercury);
	--color-secondary-light: var(--color-burnt-sienna);
	--color-secondary-dark: var(--color-nutmeg);
}
