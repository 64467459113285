@import './global/variables';
@import './global/spacing';
@import './global/typography';
@import './global/containers';

@import './components/content-block';
@import './components/footer';
@import './components/grid-scroll';
@import './components/hero';
@import './components/image-grid';
@import './components/nav';
@import './components/overlay';
@import './components/preloader';
@import './components/progress-indicator';
@import './components/audio-toggle';
@import './components/slide';
@import './components/static';
@import './components/static-hero';
@import './components/trustbar';
@import './components/work';

html,
body {
	font-family: var(--font-body);
	margin: 0;
}

audio {
	position: fixed;
}

.home.playing section:hover {
	cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='32' height='32' viewBox='0 0 100 100'%3E%3Cg clip-path='url(%23a)'%3E%3Ccircle cx='50' cy='50' r='50' fill='%23272E3A'/%3E%3Cpath fill='%23E5E5E5' stroke='%23E5E5E5' stroke-linejoin='round' stroke-width='5' d='M52 26 35 41H19v19h16l17 15V26Z'/%3E%3Cpath fill='%23E5E5E5' fill-rule='evenodd' d='M72.706 30.401a1.857 1.857 0 0 1 2.589.279A29.822 29.822 0 0 1 82 49.5a29.822 29.822 0 0 1-6.705 18.82 1.857 1.857 0 0 1-2.589.279 1.804 1.804 0 0 1-.282-2.557A26.212 26.212 0 0 0 78.317 49.5c0-6.016-2.08-11.852-5.893-16.542a1.804 1.804 0 0 1 .282-2.557Zm-4.727 4.683a1.856 1.856 0 0 1 2.575.385A23.456 23.456 0 0 1 75.223 49.5c0 5.05-1.636 9.966-4.67 14.03a1.856 1.856 0 0 1-2.574.386 1.804 1.804 0 0 1-.39-2.544c2.567-3.44 3.951-7.6 3.951-11.872 0-4.273-1.385-8.433-3.95-11.872a1.804 1.804 0 0 1 .389-2.544Zm-5.148 5.11a1.855 1.855 0 0 1 2.55.522A15.868 15.868 0 0 1 68.04 49.5c0 3.121-.924 6.174-2.658 8.784a1.855 1.855 0 0 1-2.55.522 1.806 1.806 0 0 1-.529-2.519 12.262 12.262 0 0 0 2.054-6.787c0-2.412-.714-4.771-2.054-6.787a1.806 1.806 0 0 1 .53-2.52Z' clip-rule='evenodd'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v100H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") 32 32, pointer;
}

.home.paused section:hover {
	cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='32' height='32' viewBox='0 0 100 100'%3E%3Cg clip-path='url(%23a)'%3E%3Ccircle cx='50' cy='50' r='50' fill='%23272E3A'/%3E%3Cpath fill='%23E5E5E5' stroke='%23E5E5E5' stroke-linejoin='round' stroke-width='5' d='M52 26 35 41H19v19h16l17 15V26Z'/%3E%3Cpath stroke='%23E5E5E5' stroke-linecap='round' stroke-width='5' d='m62 38 20 24m0-24L62 62'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v100H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") 32 32, pointer;
}

[data-barba="container"] {
	position: absolute;
	inset: 0;
	z-index: 1;
}