.work-grid-container {
	padding: var(--sp-xl) 0;
	overflow: hidden;
	width: 100vw;
}

.work-grid-wrap {
	display: grid;
	overflow: hidden;
	padding: var(--sp-l) var(--sp-sm);
	max-width: 100vw;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}
}

.work-item {
	box-sizing: border-box;
	cursor: pointer;
	height: 100%;
	padding: var(--sp-s);
	position: relative;

	&:hover,
	&:focus {
		&:not(.-active) {
			text-shadow: 2px 2px 0 var(--color-secondary-light);
	
			& .meta p:not(.play) {
				display: none;
			}
	
			& .meta p.play {
				display: block;
			}
	
			& .video::after {
				background-color: #0000008d;
			}
		}
	}

	&.-active {
		& img,
		& .meta {
			display: none;
			line-height: 0;
		}

		& iframe {
			opacity: 1;
			visibility: visible;
			z-index: 30;
		}

		& .video::after {
			display: none;
		}
	}

	& .video {
		aspect-ratio: 16 / 9;
		position: relative;
		width: 100%;

		&::after {
			background-color: #0000004d;
			content: "";
			inset: 0;
			position: absolute;
		}
	}

	& img {
		height: 100%;
		inset: 0;
		object-fit: contain;
		position: absolute;
		width: 100%;
	}

	& iframe {
		height: 100%;
		inset: 0;
		object-fit: contain;
		opacity: 0;
		position: absolute;
		transition: opacity 666ms ease-in-out;
		visibility: hidden;
		width: 100%;
		z-index: -1;
	}

	& .meta {
		align-items: center;
		box-sizing: border-box;
		color: var(--color-white);
		display: flex;
		font-family: var(--font-serif);
		font-size: var(--fs-0);
		height: 100%;
		justify-content: center;
		padding: var(--sp-l);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10;

		& .play {
			animation: glitch 300ms cubic-bezier(.25, .46, .45, .94) both infinite;
			display: none;
			text-shadow: 2px 2px 0 #a3bbd78d;
		}
	}
}

@keyframes glitch {
	0% {
			text-shadow: 0 0 0 #a3bbd78d;
	}
	20% {
		text-shadow: -2px 2px 0 #a3bbd78d;
	}
	40% {
		text-shadow: -2px -2px 0 #a3bbd78d;
	}
	60% {
		text-shadow: 2px 2px 0 #a3bbd78d;
	}
	80% {
		text-shadow: 2px -2px 0 #a3bbd78d;
	}
	to {
			transform: translate(0)
	}
}