:root {
  --font-display: neue-kabel, sans-serif;
  --font-body: roc-grotesk, sans-serif;
  --font-serif: space-mono, mono;
  --color-black: #000;
  --color-ebony-clay: #272e3a;
  --color-nutmeg: #783125;
  --color-pigeon-post: #a3bbd7;
  --color-burnt-sienna: #f1634a;
  --color-mercury: #e5e5e5;
  --color-white: #fff;
  --color-primary-dark: var(--color-ebony-clay);
  --color-primary-light: var(--color-mercury);
  --color-secondary-light: var(--color-burnt-sienna);
  --color-secondary-dark: var(--color-nutmeg);
}

:root {
  --fc-3xs-min: (var(--fc-s-min) * .25);
  --fc-3xs-max: (var(--fc-s-max) * .25);
  --fc-2xs-min: (var(--fc-s-min) * .5);
  --fc-2xs-max: (var(--fc-s-max) * .5);
  --fc-xs-min: (var(--fc-s-min) * .75);
  --fc-xs-max: (var(--fc-s-max) * .75);
  --fc-s-min: (var(--f-0-min, 14));
  --fc-s-max: (var(--f-0-max, 24));
  --fc-m-min: (var(--fc-s-min) * 1.5);
  --fc-m-max: (var(--fc-s-max) * 1.5);
  --fc-l-min: (var(--fc-s-min) * 2);
  --fc-l-max: (var(--fc-s-max) * 2);
  --fc-xl-min: (var(--fc-s-min) * 3);
  --fc-xl-max: (var(--fc-s-max) * 3);
  --fc-2xl-min: (var(--fc-s-min) * 4);
  --fc-2xl-max: (var(--fc-s-max) * 4);
  --fc-3xl-min: (var(--fc-s-min) * 6);
  --fc-3xl-max: (var(--fc-s-max) * 6);
  --fc-4xl-min: (var(--fc-s-min) * 8);
  --fc-4xl-max: (var(--fc-s-max) * 8);
  --sp-3xs: calc(((var(--fc-3xs-min) / 16) * 1rem)  + (var(--fc-3xs-max)  - var(--fc-3xs-min)) * var(--fluid-bp));
  --sp-2xs: calc(((var(--fc-2xs-min) / 16) * 1rem)  + (var(--fc-2xs-max)  - var(--fc-2xs-min)) * var(--fluid-bp));
  --sp-xs: calc(((var(--fc-xs-min) / 16) * 1rem)  + (var(--fc-xs-max)  - var(--fc-xs-min)) * var(--fluid-bp));
  --sp-s: calc(((var(--fc-s-min) / 16) * 1rem)  + (var(--fc-s-max)  - var(--fc-s-min)) * var(--fluid-bp));
  --sp-m: calc(((var(--fc-m-min) / 16) * 1rem)  + (var(--fc-m-max)  - var(--fc-m-min)) * var(--fluid-bp));
  --sp-l: calc(((var(--fc-l-min) / 16) * 1rem)  + (var(--fc-l-max)  - var(--fc-l-min)) * var(--fluid-bp));
  --sp-xl: calc(((var(--fc-xl-min) / 16) * 1rem)  + (var(--fc-xl-max)  - var(--fc-xl-min)) * var(--fluid-bp));
  --sp-2xl: calc(((var(--fc-2xl-min) / 16) * 1rem)  + (var(--fc-2xl-max)  - var(--fc-2xl-min)) * var(--fluid-bp));
  --sp-3xl: calc(((var(--fc-3xl-min) / 16) * 1rem)  + (var(--fc-3xl-max)  - var(--fc-3xl-min)) * var(--fluid-bp));
  --sp-4xl: calc(((var(--fc-4xl-min) / 16) * 1rem)  + (var(--fc-4xl-max)  - var(--fc-4xl-min)) * var(--fluid-bp));
  --sp-3xs-2xs: calc(((var(--fc-3xs-min) / 16) * 1rem)  + (var(--fc-2xs-max)  - var(--fc-3xs-min)) * var(--fluid-bp));
  --sp-2xs-xs: calc(((var(--fc-2xs-min) / 16) * 1rem)  + (var(--fc-xs-max)  - var(--fc-2xs-min)) * var(--fluid-bp));
  --sp-xs-s: calc(((var(--fc-xs-min) / 16) * 1rem)  + (var(--fc-s-max)  - var(--fc-xs-min)) * var(--fluid-bp));
  --sp-s-m: calc(((var(--fc-s-min) / 16) * 1rem)  + (var(--fc-m-max)  - var(--fc-s-min)) * var(--fluid-bp));
  --sp-m-l: calc(((var(--fc-m-min) / 16) * 1rem)  + (var(--fc-l-max)  - var(--fc-m-min)) * var(--fluid-bp));
  --sp-l-xl: calc(((var(--fc-l-min) / 16) * 1rem)  + (var(--fc-xl-max)  - var(--fc-l-min)) * var(--fluid-bp));
  --sp-xl-2xl: calc(((var(--fc-xl-min) / 16) * 1rem)  + (var(--fc-2xl-max)  - var(--fc-xl-min)) * var(--fluid-bp));
  --sp-2xl-3xl: calc(((var(--fc-2xl-min) / 16) * 1rem)  + (var(--fc-3xl-max)  - var(--fc-2xl-min)) * var(--fluid-bp));
  --sp-3xl-4xl: calc(((var(--fc-3xl-min) / 16) * 1rem)  + (var(--fc-4xl-max)  - var(--fc-3xl-min)) * var(--fluid-bp));
}

:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1800;
  --fluid-screen: 100vw;
  --fluid-bp: calc((var(--fluid-screen)  - var(--fluid-min-width) / 16 * 1rem) / (var(--fluid-max-width)  - var(--fluid-min-width)));
}

@media screen and (min-width: 1800px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --fs--2-min: 9.72;
  --fs--2-max: 12;
  --fs--2: calc(((var(--fs--2-min) / 16) * 1rem)  + (var(--fs--2-max)  - var(--fs--2-min)) * var(--fluid-bp));
  --fs--1-min: 11.67;
  --fs--1-max: 16.97;
  --fs--1: calc(((var(--fs--1-min) / 16) * 1rem)  + (var(--fs--1-max)  - var(--fs--1-min)) * var(--fluid-bp));
  --fs-0-min: 14;
  --fs-0-max: 24;
  --fs-0: calc(((var(--fs-0-min) / 16) * 1rem)  + (var(--fs-0-max)  - var(--fs-0-min)) * var(--fluid-bp));
  --fs-1-min: 16.8;
  --fs-1-max: 33.94;
  --fs-1: calc(((var(--fs-1-min) / 16) * 1rem)  + (var(--fs-1-max)  - var(--fs-1-min)) * var(--fluid-bp));
  --fs-2-min: 20.16;
  --fs-2-max: 47.99;
  --fs-2: calc(((var(--fs-2-min) / 16) * 1rem)  + (var(--fs-2-max)  - var(--fs-2-min)) * var(--fluid-bp));
  --fs-3-min: 24.19;
  --fs-3-max: 67.85;
  --fs-3: calc(((var(--fs-3-min) / 16) * 1rem)  + (var(--fs-3-max)  - var(--fs-3-min)) * var(--fluid-bp));
  --fs-4-min: 29.03;
  --fs-4-max: 95.94;
  --fs-4: calc(((var(--fs-4-min) / 16) * 1rem)  + (var(--fs-4-max)  - var(--fs-4-min)) * var(--fluid-bp));
  --fs-5-min: 34.84;
  --fs-5-max: 135.66;
  --fs-5: calc(((var(--fs-5-min) / 16) * 1rem)  + (var(--fs-5-max)  - var(--fs-5-min)) * var(--fluid-bp));
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-family: var(--font-display);
  font-style: normal;
  font-weight: 900;
}

h1, .h1 {
  font-family: var(--font-display);
  font-size: var(--fs-5);
  font-style: normal;
  font-weight: 900;
}

h2, .h2 {
  font-size: var(--fs-3);
}

h3, .h3 {
  font-family: var(--font-body);
  font-size: var(--fs-1);
  font-weight: 400;
}

.display {
  font-family: var(--font-display);
  font-size: var(--fs-5);
  font-style: normal;
  font-weight: 900;
}

.-upper {
  text-transform: uppercase;
}

.-center {
  text-align: center;
}

.-bold {
  font-weight: 900;
}

.-no-bold {
  font-weight: 400;
}

.-light {
  color: var(--color-white);
}

a {
  color: currentColor;
  text-decoration: none;
}

.-no-margin {
  margin: 0;
}

.container {
  box-sizing: border-box;
  padding: var(--sp-2xs) var(--sp-s);
  max-width: 1720px;
  margin: 0 auto;
}

.container.-sm {
  max-width: 1296px;
}

.container.m0 {
  margin: 0;
}

.-highlight {
  background-color: var(--color-secondary-light);
  color: var(--c-white);
  min-height: 100vh;
}

.-highlight h2 {
  color: var(--color-secondary-dark);
}

.content-block {
  overflow: hidden;
}

.content-block:not(.about) {
  align-items: center;
  max-width: 100%;
  display: flex;
}

.content-block.-highlight:not(.about) {
  min-height: auto;
}

.content-block h2 {
  margin: 0;
  line-height: 1;
}

@media (min-width: 1024px) {
  .content-block .callout {
    max-width: 80%;
  }
}

.content-block.text-only {
  padding: var(--sp-2xl);
  flex-direction: column;
}

@media (min-width: 1024px) {
  .content-block.text-only .callout {
    max-width: 80%;
  }
}

.content-block.text-only .text {
  color: var(--color-secondary-dark);
  font-family: var(--font-serif);
}

@media (min-width: 1024px) {
  .content-block.text-only .text {
    gap: var(--sp-l);
    max-width: 60%;
    display: flex;
  }
}

.content-block.about {
  padding: var(--sp-2xl) 0 var(--sp-s);
}

@media (min-width: 768px) {
  .content-block.about {
    padding: var(--sp-2xl) 0;
  }
}

.content-block.about.-highlight .bio .body {
  color: var(--color-secondary-dark);
}

.content-block.about .bio {
  display: grid;
}

@media (min-width: 768px) {
  .content-block.about .bio {
    gap: var(--sp-l);
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 50px 1fr;
  }
}

.content-block.about .bio img {
  object-fit: cover;
  grid-area: 1 / 1 / -1 / span 1;
  width: 90%;
  height: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .content-block.about .bio img {
    width: 65%;
    margin: 0 0 0 auto;
  }
}

.content-block.about .bio .content {
  text-align: center;
  grid-column: 1 / -1;
  align-self: center;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .content-block.about .bio .content {
    text-align: left;
    grid-area: 1 / 2 / -1 / -1;
    margin-top: 0;
  }
}

.content-block.about .bio .body {
  color: var(--color-pigeon-post);
  font-family: var(--font-serif);
}

.content-block.about .bio .body a {
  text-decoration: underline;
}

.content-block.about .bio .body a:hover {
  color: var(--color-white);
}

.content-block.about .more {
  display: grid;
}

.content-block.about .more .content {
  padding-bottom: var(--sp-l);
}

@media (min-width: 768px) {
  .content-block.about .more {
    gap: var(--sp-l);
    grid-template-columns: 1fr 1fr 1fr;
  }

  .content-block.about .more .content {
    padding-bottom: 0;
  }
}

.footer {
  background-color: var(--color-black);
  color: var(--color-white);
  z-index: 15;
  position: relative;
}

.footer .topline {
  gap: var(--sp-l);
  padding: var(--sp-l);
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

@media (min-width: 768px) {
  .footer .topline {
    padding: 0 var(--sp-l);
    justify-content: space-between;
  }
}

.footer .topline p {
  margin: 0;
  padding: 0;
}

.footer .topline a {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer .topline a svg {
  padding-bottom: var(--sp-s);
  transition: transform .3s;
}

.footer .topline a:hover svg, .footer .topline a:focus svg {
  transform: translateX(20%);
}

.footer .copy {
  border-top: 3px solid var(--color-white);
  font-family: var(--font-body);
  padding: var(--sp-m);
  text-align: center;
  flex-flow: column wrap;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 768px) {
  .footer .copy {
    padding: 0 var(--sp-m);
    text-align: left;
    flex-direction: row;
  }
}

.footer .copy .list-image {
  max-width: 150px;
}

@media (min-width: 768px) {
  .footer .copy .list-image {
    max-width: 100px;
    margin-right: 2rem;
  }
}

.footer .copy ul {
  gap: var(--sp-m);
  flex-flow: column wrap;
  padding: 0;
  list-style: none;
  display: flex;
}

@media (min-width: 768px) {
  .footer .copy ul {
    flex-direction: row;
    align-items: center;
  }
}

.footer .copy a {
  position: relative;
}

.footer .copy a:after {
  background: var(--color-primary-light);
  content: "";
  opacity: 0;
  transform: translateY(var(--sp-s));
  width: 100%;
  height: 1px;
  transition: opacity .3s, transform .3s;
  position: absolute;
  top: 100%;
  left: 0;
}

.footer .copy a:hover:after, .footer .copy a:focus:after {
  opacity: 1;
  transform: translateY(0);
}

.grid-scroll {
  contain: layout;
  z-index: 1;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  display: grid;
  position: relative;
}

@media (min-width: 768px) {
  .grid-scroll {
    grid-template-columns: repeat(8, 1fr);
  }
}

.grid-scroll.-last {
  padding-bottom: 50vh;
}

.grid-scroll-item {
  grid-column: var(--c);
  grid-row: var(--r);
  will-change: transform;
  position: relative;
}

.grid-scroll-item-img {
  aspect-ratio: 1;
  will-change: transform, opacity;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: auto;
  position: relative;
}

.cover {
  color: var(--color-white);
  flex-direction: column;
  grid-area: 1 / 1 / auto / -1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
}

.cover .social {
  gap: var(--sp-l);
  display: flex;
}

.cover .social svg {
  fill: currentColor;
}

.cover__title {
  margin: 0;
}

.cover__title sup {
  vertical-align: 75%;
  font-size: 4vw;
  font-weight: bold;
}

.cover__subtitle {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 400;
}

.hero {
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  display: flex;
}

.hero h1 {
  color: #fff;
  padding: var(--sp-m);
  margin: 0;
}

@media (min-width: 768px) {
  .hero h1 {
    padding: 0;
  }
}

.hero .line-mask {
  overflow: hidden;
}

.hero .split-line {
  text-align: center !important;
}

.video-wrap {
  pointer-events: none;
  z-index: -1;
  position: absolute;
  inset: 0;
}

.video-wrap .video {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-49%, -49%);
}

@media (min-width: 768px) {
  .video-wrap .video {
    width: 638px;
    height: 332px;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -45%);
  }
}

.video-wrap video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.grid {
  background-color: var(--color-primary-dark);
}

.image-grid {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 768px) {
  .image-grid {
    grid-template-rows: 16% 16% 18% 6% 3% 29% 12%;
    grid-template-columns: 39.2107% 6.7473% 11.1719% 26.1025% 14.8313%;
  }
}

.image-grid img {
  max-height: 350px;
}

@media (min-width: 768px) {
  .image-grid img {
    max-height: none;
  }
}

.image-grid > :first-child {
  color: var(--color-white);
  z-index: 1;
  width: 100%;
}

@media (min-width: 768px) {
  .image-grid > :first-child {
    grid-area: 1 / 1 / span 2 / span 3;
  }
}

.image-grid > :nth-child(2) {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .image-grid > :nth-child(2) {
    grid-area: 2 / 3 / span 3 / -1;
  }
}

.image-grid > :nth-child(3) {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .image-grid > :nth-child(3) {
    grid-row: 4 / 7;
  }
}

.image-grid > :last-child {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .image-grid > :last-child {
    grid-area: 6 / 3 / -1 / 5;
  }
}

.nav {
  color: var(--color-white);
  padding: var(--sp-s) 0;
  z-index: 30;
  background: linear-gradient(#000 -26.05%, #0000 94.47%);
  width: 100%;
  position: fixed;
  top: 0;
}

@media (min-width: 768px) {
  .nav {
    padding: var(--sp-l) 0;
  }
}

.nav .container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav ul {
  align-items: center;
  gap: var(--sp-m);
  padding: var(--sp-2xs) 0 0 0;
  text-align: center;
  margin: 0 auto;
  list-style: none;
  display: flex;
}

@media (min-width: 768px) {
  .nav ul {
    text-align: left;
    padding: 0;
  }
}

.nav ul li {
  flex: 0 33.3333%;
  position: relative;
}

@media (min-width: 768px) {
  .nav ul li {
    flex: auto;
  }
}

.nav .logo {
  font-family: var(--font-display);
  font-size: var(--fs-4);
  padding-bottom: var(--sp-s);
  align-items: center;
  margin: 0;
  font-weight: 900;
  line-height: 0;
  display: flex;
}

.nav .logo img {
  margin-right: var(--sp-2xs);
}

.nav a:not(.logo a):after {
  background: var(--color-primary-light);
  content: "";
  opacity: 0;
  transform: translateY(var(--sp-s));
  width: 100%;
  height: 1px;
  transition: opacity .3s, transform .3s;
  position: absolute;
  top: 100%;
  left: 0;
}

.nav a:not(.logo a):not(.logo):hover:after, .nav a:not(.logo a):not(.logo):focus:after {
  opacity: 1;
  transform: translateY(0);
}

.sound {
  align-item: center;
  margin-top: var(--sp-s);
  opacity: 1;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  transition: opacity .3s cubic-bezier(.83, 0, .17, 1);
  display: flex;
}

.sound.-hide {
  opacity: 0;
}

.sound p {
  margin: var(--sp-2xs) 0 0;
}

.sound button {
  border: 1px solid var(--color-white);
  color: var(--color-white);
  cursor: pointer;
  background-color: #0000;
}

.sound button.-active {
  background-color: var(--color-white);
  color: var(--color-black);
}

.sound button:not(.-active):hover, .sound button:not(.-active):focus {
  background-color: #ffffff80;
}

.sound .hint {
  margin: 0;
}

.sound .hint.-hide {
  display: none;
}

.overlay-wrap {
  color: var(--color-white);
  opacity: 0;
  position: absolute;
  inset: 1px;
}

.overlay-wrap .overlay {
  background-color: #0000004d;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.preloader {
  background-color: var(--color-black);
  z-index: 50;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.preloader .mask {
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
  overflow: hidden;
}

.preloader .logo {
  -webkit-clip-path: url("#logoClip");
  clip-path: url("#logoClip");
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.progress-wrap {
  cursor: pointer;
  mix-blend-mode: difference;
  z-index: 10;
  border-radius: 50px;
  width: 46px;
  height: 46px;
  display: block;
  position: fixed;
  top: 50%;
  right: 30px;
  transform: translateY(50%);
  box-shadow: inset 0 0 0 2px #0000001a;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
}

.progress-wrap:after {
  color: var(--color-primary-light);
  content: "↡";
  cursor: pointer;
  text-align: center;
  z-index: 1;
  width: 46px;
  height: 46px;
  font-size: 24px;
  line-height: 46px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  box-sizing: border-box;
  stroke: var(--color-primary-light);
  stroke-width: 4px;
}

.audio-wrap {
  box-shadow: inset 0 0 0 2px var(--color-white);
  cursor: pointer;
  mix-blend-mode: difference;
  z-index: 10;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 38px;
  line-height: 0;
  display: none;
  position: fixed;
  bottom: 38px;
  right: 30px;
  transform: translateY(50%);
}

@media (min-width: 768px) {
  .audio-wrap {
    display: flex;
  }
}

.audio-wrap p {
  color: var(--color-white);
  text-align: center;
  align-self: start;
  width: 100%;
  font-size: 10px;
  line-height: .5;
  position: absolute;
  top: -35px;
}

.audio-wrap > * {
  pointer-events: none;
}

.audio-wrap.-active path {
  animation: .5s ease-in-out infinite alternate oscillate;
}

.audio-wrap.-active:first-child {
  transform: scaleY(.5);
}

.audio-wrap.-active:nth-child(2) {
  transform: scaleY(.25);
}

.audio-wrap.-active:nth-child(3), .audio-wrap.-active:nth-child(4) {
  transform: scaleY(.5);
}

.audio-wrap path {
  fill: var(--color-white);
  transform-origin: center;
  transition: transform .3s;
}

.audio-wrap path:first-child {
  animation-delay: .1s;
  transform: scaleY(.1);
}

.audio-wrap path:nth-child(2) {
  animation-delay: .3s;
  transform: scaleY(.1);
}

.audio-wrap path:nth-child(3) {
  animation-delay: .5s;
  transform: scaleY(.1);
}

.audio-wrap path:nth-child(4) {
  animation-delay: .7s;
  transform: scaleY(.1);
}

@keyframes oscillate {
  to {
    transform: scaleY(1);
  }
}

.text-marq-wrap {
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100vh;
  display: flex;
  transform: translateY(-200px);
}

@media (min-width: 768px) {
  .text-marq-wrap {
    transform: translateY(0);
  }
}

.text-marq, .trust {
  color: var(--color-white);
  width: 100%;
  max-width: 1440px;
  margin: 0;
  overflow: hidden;
}

.text-marq {
  transform: translateX(100vw);
}

p.text-marq {
  font-size: var(--fs--1);
}

h2.text-marq {
  font-size: var(--fs-3);
}

@media (min-width: 1024px) {
  h2.text-marq {
    font-size: var(--fs-5);
  }
}

.title {
  font-size: var(--fs--1);
  font-weight: 500;
  line-height: 1;
  display: block;
  position: relative;
}

@media (min-width: 1024px) {
  .title {
    font-size: var(--fs--1);
  }
}

.text {
  will-change: transform;
  align-items: center;
  padding-left: .25rem;
  display: inline-flex;
}

.marquee {
  box-sizing: border-box;
  font-size: inherit;
  padding: calc(var(--sp-2xs) / 2);
  align-items: center;
  width: 245px;
  margin: 0;
  font-weight: 500;
  line-height: 1;
  display: flex;
  overflow: hidden;
}

.marquee:not(:last-child) {
  border-right: 1px solid var(--c-white);
}

.marquee:last-child {
  border-left: 1px solid var(--c-white);
  margin-left: auto;
}

.marquee .title {
  white-space: nowrap;
}

.slide {
  box-sizing: border-box;
  padding: 0 var(--sp-l);
  z-index: 5;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

canvas {
  mix-blend-mode: exclusion;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  display: block;
}

#static {
  background-color: var(--color-black);
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.static-hero-media {
  color: var(--color-white);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.static-hero-media video, .static-hero-media img {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

.static-hero-media > h1, .static-hero-media .title {
  position: absolute;
}

.static-hero-media .title {
  flex-direction: column;
  display: flex;
}

.static-hero-media .title > h1 {
  margin: 0;
}

.static-hero-media .text-marq {
  transform: translateX(0);
}

.static-hero-media .text-marq .title {
  display: block;
  position: relative;
}

.trustbar {
  background-color: var(--color-primary-dark);
  align-items: center;
  gap: var(--sp-xl);
  padding-bottom: var(--sp-3xl);
  padding-top: var(--sp-3xl);
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

@media (min-width: 768px) {
  .trustbar {
    gap: var(--sp-3xl);
    flex-wrap: nowrap;
  }
}

.trustbar img {
  overflow: hidden;
}

.work-grid-container {
  padding: var(--sp-xl) 0;
  width: 100vw;
  overflow: hidden;
}

.work-grid-wrap {
  padding: var(--sp-l) var(--sp-sm);
  max-width: 100vw;
  display: grid;
  overflow: hidden;
}

@media (min-width: 768px) {
  .work-grid-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

.work-item {
  box-sizing: border-box;
  cursor: pointer;
  padding: var(--sp-s);
  height: 100%;
  position: relative;
}

.work-item:hover:not(.-active), .work-item:focus:not(.-active) {
  text-shadow: 2px 2px 0 var(--color-secondary-light);
}

.work-item:hover:not(.-active) .meta p:not(.play), .work-item:focus:not(.-active) .meta p:not(.play) {
  display: none;
}

.work-item:hover:not(.-active) .meta p.play, .work-item:focus:not(.-active) .meta p.play {
  display: block;
}

.work-item:hover:not(.-active) .video:after, .work-item:focus:not(.-active) .video:after {
  background-color: #0000008d;
}

.work-item.-active img, .work-item.-active .meta {
  line-height: 0;
  display: none;
}

.work-item.-active iframe {
  opacity: 1;
  visibility: visible;
  z-index: 30;
}

.work-item.-active .video:after {
  display: none;
}

.work-item .video {
  aspect-ratio: 16 / 9;
  width: 100%;
  position: relative;
}

.work-item .video:after {
  content: "";
  background-color: #0000004d;
  position: absolute;
  inset: 0;
}

.work-item img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.work-item iframe {
  object-fit: contain;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: opacity .666s ease-in-out;
  position: absolute;
  inset: 0;
}

.work-item .meta {
  box-sizing: border-box;
  color: var(--color-white);
  font-family: var(--font-serif);
  font-size: var(--fs-0);
  padding: var(--sp-l);
  z-index: 10;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.work-item .meta .play {
  text-shadow: 2px 2px #a3bbd78d;
  animation: .3s cubic-bezier(.25, .46, .45, .94) infinite both glitch;
  display: none;
}

@keyframes glitch {
  0% {
    text-shadow: 0 0 #a3bbd78d;
  }

  20% {
    text-shadow: -2px 2px #a3bbd78d;
  }

  40% {
    text-shadow: -2px -2px #a3bbd78d;
  }

  60% {
    text-shadow: 2px 2px #a3bbd78d;
  }

  80% {
    text-shadow: 2px -2px #a3bbd78d;
  }

  to {
    transform: translate(0);
  }
}

html, body {
  font-family: var(--font-body);
  margin: 0;
}

audio {
  position: fixed;
}

.home.playing section:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='32' height='32' viewBox='0 0 100 100'%3E%3Cg clip-path='url(%23a)'%3E%3Ccircle cx='50' cy='50' r='50' fill='%23272E3A'/%3E%3Cpath fill='%23E5E5E5' stroke='%23E5E5E5' stroke-linejoin='round' stroke-width='5' d='M52 26 35 41H19v19h16l17 15V26Z'/%3E%3Cpath fill='%23E5E5E5' fill-rule='evenodd' d='M72.706 30.401a1.857 1.857 0 0 1 2.589.279A29.822 29.822 0 0 1 82 49.5a29.822 29.822 0 0 1-6.705 18.82 1.857 1.857 0 0 1-2.589.279 1.804 1.804 0 0 1-.282-2.557A26.212 26.212 0 0 0 78.317 49.5c0-6.016-2.08-11.852-5.893-16.542a1.804 1.804 0 0 1 .282-2.557Zm-4.727 4.683a1.856 1.856 0 0 1 2.575.385A23.456 23.456 0 0 1 75.223 49.5c0 5.05-1.636 9.966-4.67 14.03a1.856 1.856 0 0 1-2.574.386 1.804 1.804 0 0 1-.39-2.544c2.567-3.44 3.951-7.6 3.951-11.872 0-4.273-1.385-8.433-3.95-11.872a1.804 1.804 0 0 1 .389-2.544Zm-5.148 5.11a1.855 1.855 0 0 1 2.55.522A15.868 15.868 0 0 1 68.04 49.5c0 3.121-.924 6.174-2.658 8.784a1.855 1.855 0 0 1-2.55.522 1.806 1.806 0 0 1-.529-2.519 12.262 12.262 0 0 0 2.054-6.787c0-2.412-.714-4.771-2.054-6.787a1.806 1.806 0 0 1 .53-2.52Z' clip-rule='evenodd'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v100H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") 32 32, pointer;
}

.home.paused section:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='32' height='32' viewBox='0 0 100 100'%3E%3Cg clip-path='url(%23a)'%3E%3Ccircle cx='50' cy='50' r='50' fill='%23272E3A'/%3E%3Cpath fill='%23E5E5E5' stroke='%23E5E5E5' stroke-linejoin='round' stroke-width='5' d='M52 26 35 41H19v19h16l17 15V26Z'/%3E%3Cpath stroke='%23E5E5E5' stroke-linecap='round' stroke-width='5' d='m62 38 20 24m0-24L62 62'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v100H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") 32 32, pointer;
}

[data-barba="container"] {
  z-index: 1;
  position: absolute;
  inset: 0;
}

/*# sourceMappingURL=index.2aa81aae.css.map */
