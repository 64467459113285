.static-hero-media {
  align-items: center;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  position: relative;

  & video,
  & img {
    height: 100vh;
    object-fit: cover;
    width: 100vw;
  }

  & > h1,
  & .title {
    position: absolute;
  }

  & .title {
    flex-direction: column;
    display: flex;

    & > h1 {
      margin: 0;
    }
  }

  & .text-marq {
    transform: translateX(0);

    & .title {
      display: block;
      position: relative;
    }
  }
}