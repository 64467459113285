.footer {
  background-color: var(--color-black);
  color: var(--color-white);
  position: relative;
  z-index: 15;

  & .topline {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sp-l);
    justify-content: center;
    padding: var(--sp-l);

    @media (min-width: 768px) {
      justify-content: space-between;
      padding: 0 var(--sp-l);
    }

    & p {
      margin: 0;
      padding: 0;
    }

    & a {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      & svg {
        padding-bottom: var(--sp-s);
        transition: transform 300ms ease;
      }

      &:hover,
      &:focus {
        & svg {
          transform: translateX(20%);
        }
      }
    }
  }

  & .copy {
    border-top: 3px solid var(--color-white);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family: var(--font-body);
    justify-content: space-between;
    padding: var(--sp-m);
    text-align: center;

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 0 var(--sp-m);
      text-align: left;
    }

    & .list-image {
      max-width: 150px;

      @media (min-width: 768px) {
        margin-right: 2rem;
        max-width: 100px;
      }
    }

    & ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: var(--sp-m);
      list-style: none;
      padding: 0;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }

    & a {
      position: relative;

      &::after {
        background: var(--color-primary-light);
        content: "";
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 100%;
        transform: translateY(var(--sp-s));
        transition: opacity 300ms, transform 300ms;
        width: 100%;
      }

      &:hover,
      &:focus {
        &::after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}
