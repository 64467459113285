/* @link https://utopia.fyi/type/calculator?c=320,14,1.2,1800,24,1.414,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

:root {
	--fluid-min-width: 320;
	--fluid-max-width: 1800;

	--fluid-screen: 100vw;
	--fluid-bp: calc(
		(var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
			(var(--fluid-max-width) - var(--fluid-min-width))
	);
}

@media screen and (min-width: 1800px) {
	:root {
		--fluid-screen: calc(var(--fluid-max-width) * 1px);
	}
}

:root {
	--fs--2-min: 9.72;
	--fs--2-max: 12.00;
	--fs--2: calc(
		((var(--fs--2-min) / 16) * 1rem) + (var(--fs--2-max) - var(--fs--2-min)) *
			var(--fluid-bp)
	);

	--fs--1-min: 11.67;
	--fs--1-max: 16.97;
	--fs--1: calc(
		((var(--fs--1-min) / 16) * 1rem) + (var(--fs--1-max) - var(--fs--1-min)) *
			var(--fluid-bp)
	);

	--fs-0-min: 14.00;
	--fs-0-max: 24.00;
	--fs-0: calc(
		((var(--fs-0-min) / 16) * 1rem) + (var(--fs-0-max) - var(--fs-0-min)) *
			var(--fluid-bp)
	);

	--fs-1-min: 16.80;
	--fs-1-max: 33.94;
	--fs-1: calc(
		((var(--fs-1-min) / 16) * 1rem) + (var(--fs-1-max) - var(--fs-1-min)) *
			var(--fluid-bp)
	);

	--fs-2-min: 20.16;
	--fs-2-max: 47.99;
	--fs-2: calc(
		((var(--fs-2-min) / 16) * 1rem) + (var(--fs-2-max) - var(--fs-2-min)) *
			var(--fluid-bp)
	);

	--fs-3-min: 24.19;
	--fs-3-max: 67.85;
	--fs-3: calc(
		((var(--fs-3-min) / 16) * 1rem) + (var(--fs-3-max) - var(--fs-3-min)) *
			var(--fluid-bp)
	);

	--fs-4-min: 29.03;
	--fs-4-max: 95.94;
	--fs-4: calc(
		((var(--fs-4-min) / 16) * 1rem) + (var(--fs-4-max) - var(--fs-4-min)) *
			var(--fluid-bp)
	);

	--fs-5-min: 34.84;
	--fs-5-max: 135.66;
	--fs-5: calc(
		((var(--fs-5-min) / 16) * 1rem) + (var(--fs-5-max) - var(--fs-5-min)) *
			var(--fluid-bp)
	);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
	font-family: var(--font-display);
	font-style: normal;
	font-weight: 900;
}

h1,
.h1 {
	font-family: var(--font-display);
	font-size: var(--fs-5);
	font-style: normal;
	font-weight: 900;
}

h2,
.h2 {
	font-size: var(--fs-3);
}

h3,
.h3 {
	font-family: var(--font-body);
	font-size: var(--fs-1);
	font-weight: 400;
}

.display {
	font-family: var(--font-display);
	font-size: var(--fs-5);
	font-style: normal;
	font-weight: 900;
}

.-upper {
	text-transform: uppercase;
}

.-center {
	text-align: center;
}

.-bold {
	font-weight: 900;
}

.-no-bold {
	font-weight: 400;
}

.-light {
	color: var(--color-white);
}

a {
	color: currentColor;
	text-decoration: none;
}

.-no-margin {
	margin: 0;
}