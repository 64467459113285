.overlay-wrap {
	color: var(--color-white);
	inset: 1;
	opacity: 0;
	position: absolute;
	
	& .overlay {
		align-items: center;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		height: 100%;
		justify-content: center;
	}
}