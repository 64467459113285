.text-marq-wrap {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height: 100vh;
	transform: translateY(-200px);
	
	@media (min-width: 768px) {
		transform: translateY(0);
	}
}

.text-marq,
.trust {
	color: var(--color-white);
	margin: 0;
	max-width: 1440px;
	overflow: hidden;
	width: 100%;
}

.text-marq {
	transform: translateX(100vw);
}

p.text-marq {
	font-size: var(--fs--1);
}

h2.text-marq {
	font-size: var(--fs-3);
	
	@media (min-width: 1024px) {
		font-size: var(--fs-5);
	}
}

.title {
	display: block;
	font-size: var(--fs--1);
	font-weight: 500;
	line-height: 1;
	position: relative;

	@media (min-width: 1024px) {
		font-size: var(--fs--1);
	}
}

.text {
	align-items: center;
	display: inline-flex;
	padding-left: 0.25rem;
	will-change: transform;
}

.marquee {
	align-items: center;
	box-sizing: border-box;
	display: flex;
	font-size: inherit;
	font-weight: 500;
	line-height: 1;
	margin: 0;
	overflow: hidden;
	padding: calc(var(--sp-2xs) / 2);
	width: 245px;

	&:not(:last-child) {
		border-right: 1px solid var(--c-white);
	}

	&:last-child {
		border-left: 1px solid var(--c-white);
		margin-left: auto;
	}

	& .title {
		white-space: nowrap;
	}
}

.slide {
	box-sizing: border-box;
	min-height: 100vh;
	overflow: hidden;
	padding: 0 var(--sp-l);
	position: relative;
	width: 100vw;
	z-index: 5;
}
