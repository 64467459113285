
.nav {
	background: linear-gradient(180deg, #000000 -26.05%, transparent 94.47%);
	color: var(--color-white);
	padding: var(--sp-s) 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 30;

	@media (min-width: 768px) {
		padding: var(--sp-l) 0;
	}

	& .container {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	& ul {
		align-items: center;
		display: flex;
		gap: var(--sp-m);
		list-style: none;
		margin: 0 auto;
		padding: var(--sp-2xs) 0 0 0;
		text-align: center;

		@media (min-width: 768px) {
			padding: 0;
			text-align: left;
		}

		& li {
			flex: 0 1 33.33333%;
			position: relative;

			@media (min-width: 768px) {
				flex: auto;
			}
		}
	}

	& .logo {
		align-items: center;
		display: flex;
		font-family: var(--font-display);
		font-size: var(--fs-4);
		font-weight: 900;
		line-height: 0;
		margin: 0;
		padding-bottom: var(--sp-s);

		& img {
			margin-right: var(--sp-2xs);
		}
	}

	& a:not(.logo a) {
		&::after {
			background: var(--color-primary-light);
			content: '';
			height: 1px;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 100%;
			transform: translateY(var(--sp-s));
			transition: opacity 300ms, transform 300ms;
			width: 100%;
		}

		&:not(.logo):hover,
		&:not(.logo):focus {
			&::after {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}

.sound {
	align-item: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: var(--sp-s);
	opacity: 1;
	text-align: center;
	transition: opacity 300ms cubic-bezier(0.83, 0, 0.17, 1);

	&.-hide {
		opacity: 0;
	}

	& p {
		margin: var(--sp-2xs) 0 0;
	}

	& button {
		background-color: transparent;
		border: 1px solid var(--color-white);
		color: var(--color-white);
		cursor: pointer;

		&.-active {
			background-color: var(--color-white);
			color: var(--color-black);
		}

		&:not(.-active):hover,
		&:not(.-active):focus {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}

	& .hint {
		margin: 0;

		&.-hide {
			display: none;
		}
	}
}