.preloader {
  align-items: center;
  background-color: var(--color-black);
  display: flex;
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: 50;
  pointer-events: none;

  & .mask {
    height: 4.5rem;
    overflow: hidden;
    width: 4.5rem;
    position: relative;
  }

  & .logo {
    clip-path: url("#logoClip");
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}