.hero {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 100%;
	min-width: 100%;
	text-align: center;

	& h1 {
		color: #fff;
		margin: 0;
		padding: var(--sp-m);

		@media (min-width: 768px) {
			padding: 0;
		}
	}

	& .line-mask {
		overflow: hidden;
	}

	& .split-line {
		text-align: center!important;
	}
}

.video-wrap {
	inset: 0;
  pointer-events: none;
	position: absolute;
	z-index: -1;

	& .video {
		height: 100%;
		left: 50%;
		position: fixed;
		top: 50%;
		transform: translate(-49%, -49%);
		width: 100%;
		z-index: -1;
		
		@media (min-width: 768px) {
			height: 332px;
			left: 50%;
			top: 50%;
			transform: translate(-45%, -45%);
			width: 638px;
		}
	}

	& video {
		height: 100%;
		object-fit: cover;
    width: 100%;
	}
}