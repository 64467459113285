.container {
	box-sizing: border-box;
  margin: 0 auto;
	max-width: 1720px;
	padding: var(--sp-2xs) var(--sp-s);

  &.-sm {
    max-width: 1296px;
  }

  &.m0 {
    margin: 0;
  }
}

.-highlight {
  background-color: var(--color-secondary-light);
  color: var(--c-white);
  min-height: 100vh;

  & h2 {
    color: var(--color-secondary-dark);
  }
}