.grid-scroll {
	contain: layout;
	display: grid;
	grid-template-columns: repeat(4,1fr);
	position: relative;
	width: 100%;
	z-index: 1;

	@media (min-width: 768px) {
		grid-template-columns: repeat(8,1fr);
	}

    &.-last {
        padding-bottom: 50vh;
    }
}

.grid-scroll-item {
	grid-column: var(--c);
	grid-row: var(--r);
	position: relative;
	will-change: transform;
}

.grid-scroll-item-img {
	aspect-ratio: 1;
	background-position: 50% 50%;
	background-size: cover;
	height: auto;
	position: relative;
	width: 100%;
	will-change: transform, opacity;
}

.cover {
	align-items: center;
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	grid-column: 1 / -1;
	grid-row: 1;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: sticky;
	top: 0;
	width: 100%;

	& .social {
		display: flex;
		gap: var(--sp-l);

		& svg {
			fill: currentColor;
		}
	}
}

.cover__title {
	margin: 0;
}

.cover__title sup {
	font-size: 4vw;
	font-weight: bold;
	vertical-align: 75%;
}

.cover__subtitle {
	font-size: 1.5rem;
	font-weight: 400;
	margin: 0;
}
