.content-block {
	overflow: hidden;

	&:not(.about) {
		align-items: center;
		display: flex;
		max-width: 100%;

		&.-highlight {
			min-height: auto;
		}
	}

	& h2 {
		line-height: 1;
		margin: 0;
	}

	& .callout {
		@media (min-width: 1024px) {
			max-width: 80%;
		}
	}

	&.text-only {
		flex-direction: column;
		padding: var(--sp-2xl);

		& .callout {
			@media (min-width: 1024px) {
				max-width: 80%;
			}
		}

		& .text {
			color: var(--color-secondary-dark);
			font-family: var(--font-serif);

			@media (min-width: 1024px) {
				display: flex;
				gap: var(--sp-l);
				max-width: 60%;
			}
		}

	}

	&.about {
		padding: var(--sp-2xl) 0 var(--sp-s);

		@media (min-width: 768px) {
			padding: var(--sp-2xl) 0;
		}

		&.-highlight {
			& .bio .body {
				color: var(--color-secondary-dark);
			}
		}

		& .bio {
			display: grid;

			@media (min-width: 768px) {
				gap: var(--sp-l);
				grid-template-columns: 1fr 50px 1fr;
				grid-template-rows: 1fr 1fr;
			}

			& img {
				object-fit: cover;
				grid-column: 1 / span 1;
				grid-row: 1 / -1;
				height: 100%;
				margin: 0 auto;
				width: 90%;

				@media (min-width: 768px) {
					margin: 0 0 0 auto;
					width: 65%;
				}
			}

			& .content {
				align-self: center;
				grid-column: 1 / -1;
				margin-top: 1rem;
				text-align: center;

				@media (min-width: 768px) {
					grid-column: 2 / -1;
					grid-row: 1 / -1;
					margin-top: 0;
					text-align: left;
				}
			}

			& .body {
				color: var(--color-pigeon-post);
				font-family: var(--font-serif);

				& a {
					text-decoration: underline;

					&:hover {
						color: var(--color-white);
					}
				}
			}
		}

		& .more {
			display: grid;

			& .content {
				padding-bottom: var(--sp-l);
			}

			@media (min-width: 768px) {
				gap: var(--sp-l);
				grid-template-columns: 1fr 1fr 1fr;

				& .content {
					padding-bottom: 0;
				}
			}
		}
	}
}