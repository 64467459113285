canvas {
	display: block;
	height: 100vh;
	mix-blend-mode: exclusion;
	opacity: 1;
	width: 100vw;
}

#static {
	background-color: var(--color-black);
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: -1;
}
