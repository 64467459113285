
.progress-wrap {
  border-radius: 50px;
  box-shadow: inset  0 0 0 2px rgba(0,0,0,0.1);
  cursor: pointer;
  display: block;
  height: 46px;
  mix-blend-mode: difference;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(50%);
  width: 46px;
  z-index: 10;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
}

.progress-wrap::after {
  color: var(--color-primary-light);
  content: '\21A1';
  cursor: pointer;
  display: block;
  font-size: 24px;
  height: 46px;
  left: 0;
  line-height: 46px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 46px;
  z-index: 1;
}

.progress-wrap svg path { 
  fill: none; 
}

.progress-wrap svg.progress-circle path {
  box-sizing:border-box;
  stroke: var(--color-primary-light);
  stroke-width: 4;
}
