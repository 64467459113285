
.audio-wrap {
  align-items: center;
  border-radius: 50px;
  bottom: 38px;
  box-shadow: inset  0 0 0 2px var(--color-white);
  cursor: pointer;
  display: none;
  justify-content: center;
  line-height: 0;
  mix-blend-mode: difference;
  position: fixed;
  right: 30px;
  transform: translateY(50%);
  width: 38px;
  z-index: 10;

  @media (min-width: 768px) {
    display: flex;
  }

  & p {
    align-self: start;
    color: var(--color-white);
    font-size: 10px;
    line-height: 0.5;
    position: absolute;
    text-align: center;
    top: -35px;
    width: 100%;
  }

  & > * {
    pointer-events: none;
  }
}

.audio-wrap {
  &.-active {
    & path {
      animation: 500ms oscillate infinite alternate ease-in-out;
    }

    &:nth-child(1) {
      transform: scaleY(0.5);
    }

    &:nth-child(2) {
      transform: scaleY(0.25);
    }

    &:nth-child(3) {
      transform: scaleY(0.5);
    }

    &:nth-child(4) {
      transform: scaleY(.5);
    }
  }

  & path {
    fill: var(--color-white);
    transform-origin: center;
    transition: transform 300ms ease;

    &:nth-child(1) {
      transform: scaleY(0.1);
      animation-delay: 100ms;
    }

    &:nth-child(2) {
      transform: scaleY(0.1);
      animation-delay: 300ms;
    }

    &:nth-child(3) {
      transform: scaleY(0.1);
      animation-delay: 500ms;
    }

    &:nth-child(4) {
      transform: scaleY(.1);
      animation-delay: 700ms;
    }
  }
}

@keyframes oscillate {
  to {
    transform: scaleY(1);
  }
}
